import { useDispatch } from "react-redux";

import SelectIntegration from "components/SelectIntegration";
import WbModal, { WbModalTitle } from "components/WbModal";
import { TRIGGER } from "enums/StageTypesEnum";
import { getSingleIntegration } from "../../FlowBuilderActions";

export default function SelectIntegrationModal({
  closeModal,
  stageType,
  getSingleIntegrationSideEffect,
}) {
  const dispatch = useDispatch();

  const integrationSelectedHandler = (selectedIntegration) => {
    const { _id: selectedIntegrationId } = selectedIntegration;
    dispatch(
      getSingleIntegration(selectedIntegrationId, [
        getSingleIntegrationSideEffect,
      ])
    );
    closeModal();
  };

  const findQuery =
    stageType === TRIGGER
      ? {
          triggerStageItems: {
            $exists: true,
          },
        }
      : {
          actionStageItems: {
            $exists: true,
          },
        };

  return (
    <WbModal
      isOpen={true}
      size="lg"
      toggle={closeModal}
      className="md:min-w-[750px] lg:min-w-[1000px]"
    >
      <WbModalTitle toggle={closeModal} title="Select Integration" />
      <div className="p-4">
        <SelectIntegration
          shouldLoadOAuthWindow={false}
          findQuery={findQuery}
          onSelectIntegration={integrationSelectedHandler}
        />
      </div>
    </WbModal>
  );
}
