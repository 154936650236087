import {
  BoltIcon,
  Cog8ToothIcon,
  ListBulletIcon,
  PlusCircleIcon,
  ViewColumnsIcon,
  ViewfinderCircleIcon,
  UsersIcon,
  FunnelIcon,
  AtSymbolIcon,
  SwatchIcon,
  MegaphoneIcon,
  ChartBarIcon
} from "@heroicons/react/24/solid";

interface SideBarItem {
  name: string;
  path: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const sideBarItems: SideBarItem[] = [
  {
    name: "Dashboard",
    path: "/dashboard",
    Icon: PlusCircleIcon,
  },
  {
    name: "Flows",
    path: "/flows",
    Icon: ViewColumnsIcon,
  },
  {
    name: "Tasks",
    path: "/tasks",
    Icon: ListBulletIcon,
  },
  {
    name: "Apps",
    path: "/apps",
    Icon: ViewfinderCircleIcon,
  },
  {
    name: "Built-in Tools",
    path: "/built-in-tools",
    Icon: BoltIcon,
  },
  {
    name: "Settings",
    path: "/account",
    Icon: Cog8ToothIcon,
  },
];
export const dataStudioSideBarItems: SideBarItem[] = [
  {
    name: "Dashboard",
    path: "built-in-tools/data-studio/dashboard",
    Icon: ChartBarIcon,
  },
  {
    name: "Leads",
    path: "built-in-tools/data-studio/leads",
    Icon: UsersIcon,
  },
  {
    name: "Senders",
    path: "built-in-tools/data-studio/senders",
    Icon: MegaphoneIcon,
  },
  {
    name: "Segments",
    path: "built-in-tools/data-studio/segments",
    Icon: FunnelIcon,
  },
  {
    name: "Templates",
    path: "built-in-tools/data-studio/templates",
    Icon: SwatchIcon,
  },
  {
    name: "Campaigns",
    path: "built-in-tools/data-studio/campaigns",
    Icon: AtSymbolIcon,
  },
];