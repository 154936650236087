import React, { Component } from "react";
// import Slider from "react-rangeslider";

import WbButton from "components/WbButton";

class CreateCustomPlan extends Component {
  state = {
    tasksCount: 100,
    prorataPrice: 0.01,
    newPrice: 0,
  };

  componentDidMount() {
    const { tasksCount, prorataPrice } = this.state;
    const newPrice = tasksCount * prorataPrice;
    this.setState({
      newPrice,
    });
  }

  handleOnChange = (event) => {
    const tasksCount = event.target.value;
    this.setState({
      tasksCount,
    });
    const newPrice = tasksCount * this.state.prorataPrice;
    this.setState({
      newPrice,
    });
  };

  render() {
    const { customPlan } = this.props;
    return (
      <div>
        <h6>We also offer plans for business of all sizes,</h6>
        <div className="mt-2">
          <div>
            <span>My monthly task are,</span>
            <span>{this.state.tasksCount}</span>
          </div>
        </div>
        <div className="mt-2">
          {/* <Slider
            tooltip={false}
            min={100}
            max={2000}
            value={this.state.tasksCount}
            orientation="horizontal"
            onChange={this.handleOnChange}
            labels={{
              100: "100",
              2000: "2000",
            }}
          /> */}
          <input
            type="range"
            min={100}
            max={2000}
            onChange={this.handleOnChange}
            value={this.state.tasksCount}
          />
        </div>
        <div>
          <h6>Newly calculated price is,</h6>
          <h5>
            ${this.state.newPrice.toFixed(2)}&nbsp;
            <span>/ month</span>
          </h5>
        </div>
        <div className="mx-auto mt-2 flex justify-center">
          <WbButton
            onClick={() =>
              this.props.initSelectPlanModal(customPlan, this.state.tasksCount)
            }
          >
            {this.props.buttonText}
          </WbButton>
        </div>
      </div>
    );
  }
}

export default CreateCustomPlan;
