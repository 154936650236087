import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import ContentLoader from "react-content-loader";

import { getBillingPlans } from "./SelectPlanActions";
import { isDataPresent } from "utils/object_util";
import AddCardModal from "./AddCardModal";
import ContactUsModal from "./ContactUsModal";
import EachPlanDetails from "./EachPlanDetails";
import FAQsContainer from "./FAQsContainer";
import { refreshSubscription } from "layouts/LoggedInLayout/LoggedInLayoutActions";
import {
  updatePlan,
  getPlanDetails,
} from "modules/Account/LegacyBilling/ManageSubscriptionActions";
import {
  createSubscriptionInStripe,
  loadModalIfMousePositionIsOut,
  stopModalIfMousePositionIsInside,
} from "./SelectPlanActions";
import SubscriptionPlanOpsEnum from "enums/SubscriptionPlanOpsEnum";
import CreateCustomPlanContainer from "./CreateCustomPlan";
import ComparePlans from "./ComparePlans";
import MouseOutsideWindowModal from "./MouseOutsideWindowModal";
import { FaEdit, FaUserNinja } from "react-icons/fa";

class SelectPlan extends Component {
  state = {
    shouldLoadSelectPlanContainerModal: false,
    shouldLoadContactUsModal: false,
    planSelected: null,
    shouldLoadEntepriseContactUs: false,
    shouldShowCustomPlan: false,
    shouldShowComparePlans: false,
    tasksCount: 0,
  };

  componentDidMount() {
    const { subscription } = this.props;
    this.props.getBillingPlans();
    const { billingInfo } = subscription;
    if (!billingInfo.stripeSubscriptionIds) {
      document.addEventListener("mouseleave", this.onMouseLeaveHandler);
    }
  }

  onMouseLeaveHandler = (event) => {
    const { isUserExitIntentModalShown } = this.props;

    if (
      event.clientY <= 0 ||
      event.clientX <= 0 ||
      event.clientX >= window.innerWidth ||
      event.clientY >= window.innerHeight
    ) {
      if (!isUserExitIntentModalShown) {
        this.props.loadModalIfMousePositionIsOut();
      }
    }
  };

  initSelectPlanModal = (planSelected, tasksCount) => {
    let customTasksCount = tasksCount > 0 ? tasksCount : 0;
    const { planOps, defaultCardDetails, subscription } = this.props;
    const { vendorPlanId } = planSelected;
    const { billingInfo, _id: subscriptionId } = subscription;
    const { stripeCustomerId } = billingInfo;

    if (planOps === SubscriptionPlanOpsEnum.UPDATE_PLAN) {
      const { stripeSubscriptionIds } = billingInfo;
      const { service } = stripeSubscriptionIds || {};
      const { wyzebulb: stripeSubscriptionId } = service || {};
      const sideEffectFunctions = (sideEffectData) => {
        this.props.getPlanDetails(stripeSubscriptionId, subscriptionId);
        this.props.refreshSubscription(subscriptionId);
        this.props.closeModal();
      };
      this.props.updatePlan(
        stripeSubscriptionId,
        vendorPlanId,
        customTasksCount,
        [sideEffectFunctions]
      );
    } else if (isDataPresent(defaultCardDetails)) {
      const sideEffectFunctions = (sideEffectData) => {
        this.props.closeModal();
        this.props.refreshSubscription(subscriptionId);
      };
      const isDefaultCardExist = true;

      this.props.createSubscriptionInStripe(
        stripeCustomerId,
        "",
        vendorPlanId,
        subscriptionId,
        isDefaultCardExist,
        customTasksCount,
        [sideEffectFunctions]
      );
    } else {
      this.setState({
        shouldLoadSelectPlanContainerModal: true,
        planSelected: planSelected,
        tasksCount: customTasksCount,
      });
    }
  };

  initContactUsModal = (shouldLoadEntepriseContactUs) => {
    this.setState({
      shouldLoadContactUsModal: true,
      shouldLoadEntepriseContactUs: shouldLoadEntepriseContactUs,
    });
  };

  stopContactUsModal = () => {
    this.setState({
      shouldLoadContactUsModal: false,
    });
  };

  stopSelectPlanModal = () => {
    this.setState({
      shouldLoadSelectPlanContainerModal: false,
    });
    this.props.closeModal();
  };

  createCustomPlanHandler = () => {
    this.setState({
      shouldShowCustomPlan: true,
    });
  };

  comparePlansHandler = () => {
    this.setState({
      shouldShowComparePlans: true,
    });
  };

  render() {
    const { billingPlans, defaultCardDetails } = this.props;
    const contentLoader = (
      <ContentLoader height={150} width={200} speed={3}></ContentLoader>
    );
    let plansToShow = [...Array(3).keys()].map((value) => {
      return <div key={value}>{contentLoader}</div>;
    });
    let eachButtonText = null;
    if (isDataPresent(billingPlans)) {
      const { isLoadingGetDefaultCardDetails } = this.props;
      eachButtonText = this.props.planButtonText
        ? this.props.planButtonText
        : !isDataPresent(defaultCardDetails)
        ? "Try FREE for 7 days"
        : "Select Plan";

      if (!isLoadingGetDefaultCardDetails) {
        plansToShow = _.map(billingPlans, (eachPlanToShow, index) => {
          if (index <= 2) {
            return (
              <EachPlanDetails
                shouldShowSpinner={
                  this.props.isLoadingUpdatePlan ||
                  this.props.isLoadingSubscriptionCreationInStripe
                }
                eachPlanButtonText={eachButtonText}
                eachPlanDetailsToShow={eachPlanToShow}
                initSelectPlanModal={this.initSelectPlanModal}
                indexOfEachPlan={index}
                key={index}
              />
            );
          }
        });
      }
    }

    let loadSelectedPlanModalForCardAddition = this.state
      .shouldLoadSelectPlanContainerModal ? (
      <AddCardModal
        tasksCount={this.state.tasksCount}
        planSelected={this.state.planSelected}
        closeModal={this.stopSelectPlanModal}
      />
    ) : null;

    let loadContactUsModal = this.state.shouldLoadContactUsModal ? (
      <ContactUsModal
        shouldLoadEntepriseContactUs={this.state.shouldLoadEntepriseContactUs}
        closeModal={this.stopContactUsModal}
      />
    ) : null;

    const loadModalWhenMouseMovedOut = this.props
      .shouldLoadModalIfMousePositionIsOut ? (
      <MouseOutsideWindowModal
        closeModal={this.props.stopModalIfMousePositionIsInside}
      />
    ) : null;

    return (
      <div className="flex flex-col items-center space-y-5 p-10">
        <div className="w-full">
          {/* Available Plans Section */}
          <div>
            {!this.props.isLoadingGetDefaultCardDetails && (
              <div className="mt-4 text-center font-bold">
                <h1 className="text-xl">
                  {!isDataPresent(defaultCardDetails)
                    ? `Try Wyzebulb for 7 days free, with no risk!`
                    : `Select a plan to access the dashboard, and track all your tasks and flows`}
                </h1>
              </div>
            )}
            <div className="flex flex-row items-center justify-center">
              {plansToShow}
            </div>
            {loadSelectedPlanModalForCardAddition}
            {loadContactUsModal}
            {this.props.shouldLoadFaqs ? <FAQsContainer /> : null}
          </div>
          <div className="mt-5 border-2"></div>
          {/* Custom and Enterprise Plans Section */}
          <div className="grid grid-cols-2 text-center">
            <div className="border-2 p-4">
              {this.state.shouldShowCustomPlan ? (
                <CreateCustomPlanContainer
                  {...(isDataPresent(billingPlans) && {
                    customPlan: billingPlans[3],
                  })}
                  buttonText={eachButtonText}
                  initSelectPlanModal={(vendorPlanId, tasksCount) =>
                    this.initSelectPlanModal(vendorPlanId, tasksCount)
                  }
                />
              ) : (
                <>
                  {/* <h6 className="text-lg">
                    We also offer plans for business of all sizes, send us your
                    requirement at{" "}
                    <a
                      className="mt-2 cursor-pointer space-x-2 text-blue-600 underline"
                      href="mailto: hello@wyzebulb.com"
                    >
                      hello@wyzebulb.com
                    </a>{" "}
                    to create a Custom Plan
                  </h6> */}
                  <h6 className="text-lg">We also offer plans for business of all sizes,</h6>
                  <p className="mt-2 cursor-pointer space-x-2 text-blue-800 underline">
                    <FaEdit className="inline" />
                    <span onClick={this.createCustomPlanHandler}>
                      CREATE CUSTOM PLAN
                    </span>
                  </p>
                </>
              )}
            </div>
            <div className="border-2 p-4">
              <h6 className="text-lg">Looking for ENTERPRISE plan?</h6>
              <p className="mt-2 cursor-pointer space-x-2 text-blue-600 underline">
                <FaUserNinja className="inline" />
                <span onClick={() => this.initContactUsModal(true)}>
                  CONTACT US
                </span>
              </p>
            </div>
          </div>
          <div className="border-2"></div>
          {/* Compare Plans Section */}
          <div className="mt-4 space-y-2">
            <h6 className="text-center text-lg font-bold">Compare plans</h6>
            <p className="text-center">
              Discover the differences, and chose plan fited to your needs.
            </p>
            <div className="border-2"></div>
            <ComparePlans />
          </div>
          {loadModalWhenMouseMovedOut}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  SelectPlan,
  LoggedInLayout,
  TopNav,
  ManageSubscription,
}) => {
  const {
    billingPlans,
    isLoadingSubscriptionCreationInStripe,
    shouldLoadModalIfMousePositionIsOut,
    isUserExitIntentModalShown,
  } = SelectPlan;
  const { subscription } = LoggedInLayout;
  //const { containerClassnames } = TopNav;
  const {
    isLoadingUpdatePlan,
    defaultCardDetails,
    isLoadingGetDefaultCardDetails,
  } = ManageSubscription;

  return {
    billingPlans,
    subscription,
    //containerClassnames,
    isLoadingUpdatePlan,
    defaultCardDetails,
    isLoadingSubscriptionCreationInStripe,
    isLoadingGetDefaultCardDetails,
    shouldLoadModalIfMousePositionIsOut,
    isUserExitIntentModalShown,
  };
};

SelectPlan.propTypes = {
  getBillingPlans: PropTypes.func,
  billingPlans: PropTypes.array,
  subscription: PropTypes.object,
  planButtonText: PropTypes.any,
  closeModal: PropTypes.any,
  planOps: PropTypes.any,
  shouldLoadEnterprisePlan: PropTypes.any,
  shouldLoadFaqs: PropTypes.any,
};

export default connect(mapStateToProps, {
  getBillingPlans,
  refreshSubscription,
  updatePlan,
  getPlanDetails,
  createSubscriptionInStripe,
  loadModalIfMousePositionIsOut,
  stopModalIfMousePositionIsInside,
})(SelectPlan);
