import CreateCampaign from "./index";
import SelectComponent from "../components/SelectComponent";
import React from "react";

const options = [
  { value: "sendgrid", label: "Sendgrid" },
  { value: "gmail", label: "Gmail" },
  { value: "mailerlite", label: "Mailerlite" },
];

export default function SelectProvider() {
  const [selectedComponent, setSelectedComponent] = React.useState(
    options[0].value
  );

  const handleSelectChange = (selectedValue: any) => {
    setSelectedComponent(selectedValue);
  };

  return (
    <>
      <div className="mx-8 w-[30%]">
      <h1 className="my-4 text-gray-400 mx-[-5%]">Fill the campaign details to Send Emails.</h1>
        <h1 className="mb-2">Provider:</h1>
        <SelectComponent
          options={options}
          defaultOption={options[0].value}
          onChange={handleSelectChange}
        />
      </div>
      <div className="mt-4">
        {selectedComponent === "sendgrid" && <CreateCampaign />}
        {selectedComponent === "gmail" && <CreateCampaign />}
        {selectedComponent === "mailerlite" && <CreateCampaign />}
      </div>
    </>
  );
}
