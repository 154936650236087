import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Bars3Icon } from "@heroicons/react/20/solid";

import PaymentFailureWarning from "components/SelectPlan/PaymentFailureWarning";
import { getDefaultCardDetails } from "modules/Account/LegacyBilling/ManageSubscriptionActions";

import { getSubscriptionForLoggedInUser } from "./LoggedInLayoutActions";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";

function LoggedInLayout() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { loggedInUserSubscriptionId } = useSelector(
    (state: any) => state.Auth
  );
  const { isPaymentPending, subscription, isFirstFlowCreated } = useSelector(
    (state: any) => state.LoggedInLayout
  );
  const { defaultCardDetails, isDefaultCardDetailsLoaded } = useSelector(
    (state: any) => state.ManageSubscription
  );

  useEffect(() => {
    dispatch(getSubscriptionForLoggedInUser(loggedInUserSubscriptionId));
  }, [dispatch, loggedInUserSubscriptionId]);

  useEffect(() => {
    const { billingInfo } = subscription || {};
    const { stripeCustomerId } = billingInfo || {};
    if (stripeCustomerId) {
      dispatch(getDefaultCardDetails(stripeCustomerId));
    }
  }, [subscription?.stripeCustomerId]);

  const isUserOnFlowBuilder = location.pathname === "/flow-builder";
  const isDefaultCardDetailsExist = !isEmpty(defaultCardDetails);
  const shouldShowTopAndSideNavbar = !isUserOnFlowBuilder;
  let paymentFailureWarningBanner = null;
  if (
    (isPaymentPending && isFirstFlowCreated) ||
    (isDefaultCardDetailsLoaded &&
      !isDefaultCardDetailsExist &&
      isFirstFlowCreated)
  ) {
    paymentFailureWarningBanner = (
      <div className="mb-4">
        <PaymentFailureWarning />
      </div>
    );
  }
  const isAdmin = subscription?.roles?.includes("admin");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handlesideBar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className=" font-sans dark:bg-gray-800">
      <main className="flex h-screen flex-row">
        <Sidebar
          isAdmin={isAdmin}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={handlesideBar}
        />

        <div className="w-screen">
          <div className="flex items-center gap-2 bg-gray-100 p-4 lg:hidden">
            <button onClick={handlesideBar}>
              <Bars3Icon className="h-5 w-5 text-gray-600" />
            </button>

            <Link to="/flow-library">
              <img
                src="/assets/img/logos/wyzebulb.png"
                className="object-contain "
                alt="logo"
              />
            </Link>
          </div>
          <div
            className={
              shouldShowTopAndSideNavbar
                ? "flex-1 py-4 px-2 dark:bg-gray-700 lg:ml-56"
                : "flex-1 dark:bg-gray-700 lg:ml-56"
            }
          >
            {!isUserOnFlowBuilder ? <>{paymentFailureWarningBanner}</> : null}
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
}

export default LoggedInLayout;
