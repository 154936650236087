import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentLoader from "react-content-loader";

import SearchBar from "../SearchBar";
import IntegrationCategories from "./IntegrationCategories";
import RequestIntegration from "./RequestIntegration";
import ComingSoon from "./ComingSoon";
import Integrations from "./Integrations";
import {
    fetchIntegrations,
    filterIntegrationsByCategory,
    filterIntegrationsByText,
    showRequestIntegrationModal,
    hideRequestIntegrationModal,
} from "./SelectIntegrationActions";
import WbButton from "components/WbButton";
import WbLoader from "components/WbLoader";

export default function SelectIntegration(props) {
    const { findQuery, shouldLoadOAuthWindow, onSelectIntegration } = props;
    const dispatch = useDispatch();
    const { fetchingIntegrations, integrations, shouldShowRequestIntegrationModal } = useSelector((state) => state.SelectIntegration);
    const { showOAuthBackdrop } = useSelector((state) => state.OAuth);
    const [showComingSoonNotification, setShowComingSoonNotification] = useState("");

    useEffect(() => {
        const subscriptionId = sessionStorage.getItem("subscriptionId");
        dispatch(fetchIntegrations(findQuery, null, subscriptionId));
    }, []);

    const searchIntegrationsByText = (event) => {
        const { value } = event.target;

        dispatch(filterIntegrationsByText(value, integrations, findQuery));
    };

    const searchIntegrationsByCategory = (integrationCategory) => {
        dispatch(filterIntegrationsByCategory(integrationCategory, integrations, findQuery));
    };

    const initRequestIntegration = () => {
        dispatch(showRequestIntegrationModal());
    };

    const stopRequestIntegration = () => {
        dispatch(hideRequestIntegrationModal());
    };

    const initComingSoonNotification = () => {
        setShowComingSoonNotification(true);
    };

    const stopComingSoonNotification = () => {
        setShowComingSoonNotification(false);
    };

    let integrationList = null;

    if (fetchingIntegrations && !integrations) {
        integrationList = [...Array(6).keys()].map((value) => {
            return (
                <div key={value}>
                    <ContentLoader height={180} width={200} speed={2} />
                </div>
            );
        });
    } else if (integrations) {
        integrationList = (
            <Integrations
                initComingSoonNotification={initComingSoonNotification}
                shouldLoadOAuthWindow={shouldLoadOAuthWindow}
                integrations={integrations}
                onSelectIntegration={onSelectIntegration}
            />
        );
    } else {
        integrationList = (
            <div className="w-full items-center">
                <div className="flex flex-col items-center justify-center space-y-2">
                    <i className="iconsmind-Repair text-7xl " />
                    <h1 className="text-3xl">No apps found</h1>
                    <h3 className="text-lg text-gray-500 dark:text-white">
                        Don't worry! We can build it for you.{" "}
                        <span role="img" aria-label="happy">
                            😀
                        </span>
                    </h3>
                    <h3 className="text-muted">Click the button below to request a custom app.</h3>
                    <div>
                        <WbButton onClick={initRequestIntegration}>Request app</WbButton>
                    </div>
                </div>
            </div>
        );
    }

    return (
      <div className="mt-5 flex flex-row">
        <div className="hidden w-64 cursor-pointer md:block lg:block">
          <IntegrationCategories
            searchIntegrationsByCategory={searchIntegrationsByCategory}
          />
        </div>
        <div className="flex-1">
          <SearchBar
            id="search-integration"
            placeholder="Search app"
            handleSearch={searchIntegrationsByText}
          />
          <div
            className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-${
              !integrations ? "1" : "4"
            } mt-5`}
          >
            {integrationList}
          </div>
        </div>
        {showOAuthBackdrop && <WbLoader />}
        {shouldShowRequestIntegrationModal && (
          <RequestIntegration closeModal={stopRequestIntegration} />
        )}
        {showComingSoonNotification && (
          <ComingSoon closeModal={stopComingSoonNotification} />
        )}
      </div>
    );
}
