import { useNavigate } from "react-router-dom";

interface props {
  title: string;
  description: string;
  buttonText: string;
  path: string;
}

const NoDataFound = (props: props) => {
  const navigate = useNavigate();
  return (
    <div>
      <h1 className="mt-16 flex justify-center text-2xl font-semibold">
        {props.title}
      </h1>
      <p className="mt-4 flex justify-center text-gray-500 ">
        {props.description}
      </p>
      <div className="mt-4 flex justify-center">
        <button
          className="rounded-md border bg-orange-600 p-2 text-white"
          onClick={() => navigate(`${props.path}`)}
        >
          {props.buttonText}
        </button>
      </div>
    </div>
  );
};

export default NoDataFound;
