import { HeaderSection } from "components/WbLayout/Header";
import ImportLeads from "./ImportLeads";
import { useState } from "react";
import ActionsHelper from "./ActionsHelper";
import { useLocation } from "react-router-dom";

export default function StudioHeader() {
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);

  const toggleModal = () => {
    setShowSendEmailModal(!showSendEmailModal);
  };
  const location = useLocation();
  const pathName: any = location.pathname.split("/").at(-1);

  const componentMap: any = {
    leads: <ImportLeads toggleModal={toggleModal} />,
    segments: <ImportLeads toggleModal={toggleModal} />,
  };

  const headerSectionMap: any = {
    "data-studio": (
      <HeaderSection
        title="Data Studio"
        description="Data Studio helps to manage all the sources of Data in one place."
      />
    ),
    dashboard: (
      <HeaderSection
        title="Data Studio"
        description="Data Studio helps to manage all the sources of Data in one place."
      />
    ),
    leads: (
      <HeaderSection
        title="Leads"
        description="Leads helps to manage all the sources of Data in one place."
      />
    ),
    senders: (
      <HeaderSection
        title="Senders"
        description="A sender allows you send emails to your contacts/leads."
      />
    ),
    segments: (
      <HeaderSection
        title="Segments"
        description="Segments enable you to categorize leads based om specific criteria or conditions."
      />
    ),
    templates: (
      <HeaderSection
        title="Templates"
        description="Using templates can help you save time by allowing you to create content quickly."
      />
    ),
    campaigns: (
      <HeaderSection
        title="Campaigns"
        description="Campaigns allow send emails in a customized way."
      />
    ),
  };

  return (
    <>
      <div className="flex flex-col items-center justify-between px-2 md:flex-row">
        {headerSectionMap[pathName]}
        <ActionsHelper toggleModal={toggleModal} />
        {showSendEmailModal && componentMap[pathName]}
      </div>
    </>
  );
}
