import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Fragment } from "react";

import { KBarContent, KBarRoot, KBarTrigger } from "components/Kbar/Kbar";
import UserProfileDropdown from "./UserProfileDropdown";
import WbYoutube from "components/WbYoutube";
import { Credits } from "components/Credits";
import { Dialog, Transition } from "@headlessui/react";
import { sideBarItems, dataStudioSideBarItems } from "./constants";

let supportTabExists = false;

const KBarWrapper = ({
  children,
  withKBar = false,
}: {
  withKBar: boolean;
  children: React.ReactNode;
}) =>
  withKBar ? (
    <KBarRoot>
      {children}
      <KBarContent />
    </KBarRoot>
  ) : (
    <>{children}</>
  );

function SideBarItem({
  name,
  path,
  Icon,
}: {
  name: string;
  path: string;
  Icon: React.ElementType;
}) {
  const location = useLocation();
  return (
    <li>
      <NavLink
        className={`flex items-center space-x-2 rounded px-7 py-1 ${
          location.pathname.includes(path)
            ? "rounded-md bg-gray-200 dark:bg-gray-700 dark:text-gray-200"
            : "dark:text-gray-200"
        }`}
        to={path}
      >
        <Icon className="h-5 w-5 text-gray-600 dark:text-gray-100" />
        <span className="mt-1 text-base">{name}</span>
      </NavLink>
    </li>
  );
}

export default function Sidebar(props: {
  isAdmin: boolean;
  sidebarOpen: boolean;
  setSidebarOpen: any;
}) {
  const location = useLocation();
  const { isAdmin = false, sidebarOpen, setSidebarOpen } = props;

  for (const item of sideBarItems) {
    if (item.name === "Support") {
      supportTabExists = true;
      break;
    }
  }

  if (isAdmin === true && !supportTabExists) {
    sideBarItems.push({
      name: "Support",
      path: "/support",
      Icon: ChatBubbleOvalLeftEllipsisIcon,
    });
  }
  return (
    <KBarWrapper withKBar>
      <div className="relative hidden dark:from-gray-800 dark:to-gray-700 lg:visible lg:fixed lg:inset-y-0 lg:flex lg:w-56 lg:flex-col lg:justify-between lg:bg-gray-100 lg:pt-5 lg:pb-4 dark:lg:border-gray-700 dark:lg:bg-gradient-to-b">
        <div className="scrollbar-thumb scrollbar-track-gray-0  h-[70%] scroll-m-1 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-rounded-full scrollbar-thumb-rounded-md scrollbar-w-1 dark:scrollbar-track-gray-800 dark:scrollbar-thumb-gray-700">
          <div className="flex justify-between py-2 px-6">
            <Link to="/flow-library">
              <img
                src="/assets/img/logos/wyzebulb.png"
                className="object-contain pb-3 "
                alt="logo"
              />
            </Link>
            <KBarTrigger />
          </div>
          {location.pathname.includes("data-studio") ? (
            <ul className="flex flex-col space-y-4 ">
              {dataStudioSideBarItems.map(({ name, path, Icon }, index) => (
                <SideBarItem key={index} name={name} path={path} Icon={Icon} />
              ))}
            </ul>
          ) : (
            <ul className="flex flex-col space-y-4 ">
              {sideBarItems.map(({ name, path, Icon }, index) => (
                <SideBarItem key={index} name={name} path={path} Icon={Icon} />
              ))}
            </ul>
          )}
        </div>
        <div>
          {!location.pathname.includes("data-studio") && (
            <WbYoutube
              thumbnailImg={"/assets/img/video/videoThumbnail.jpg"}
              videoUrl={"https://www.youtube.com/watch?v=z_Dw7Qo1S40"}
              title={"Create Workflows"}
              subTitle={"How to create a Workflow?"}
            />
          )}
          <UserProfileDropdown />
          <Credits />
        </div>
      </div>

      <div className="lg:hidden">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-100 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-sreen scrollbar-thumb scrollbar-track-gray-0 relative scroll-m-1 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-rounded-full scrollbar-thumb-rounded-md scrollbar-w-1 dark:from-gray-800 dark:to-gray-700 dark:scrollbar-track-gray-800 dark:scrollbar-thumb-gray-700 lg:fixed lg:inset-y-0  lg:flex lg:w-56 lg:flex-col lg:justify-between lg:bg-gray-100 lg:pt-5 lg:pb-4 dark:lg:border-gray-700 dark:lg:bg-gradient-to-b">
                    <div className="">
                      <div className="flex justify-between py-2 px-6">
                        <Link to="/flow-library">
                          <img
                            src="/assets/img/logos/wyzebulb.png"
                            className="object-contain pb-3 "
                            alt="logo"
                          />
                        </Link>
                        <KBarTrigger />
                      </div>
                      {location.pathname.includes("data-studio") ? (
                        <ul className="flex flex-col space-y-4 ">
                          {dataStudioSideBarItems.map(
                            ({ name, path, Icon }, index) => (
                              <SideBarItem
                                key={index}
                                name={name}
                                path={path}
                                Icon={Icon}
                              />
                            )
                          )}
                        </ul>
                      ) : (
                        <ul className="flex flex-col space-y-4 ">
                          {sideBarItems.map(({ name, path, Icon }, index) => (
                            <SideBarItem
                              key={index}
                              name={name}
                              path={path}
                              Icon={Icon}
                            />
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className="">
                      {!location.pathname.includes("data-studio") && (
                        <WbYoutube
                          thumbnailImg={"/assets/img/video/videoThumbnail.jpg"}
                          videoUrl={
                            "https://www.youtube.com/watch?v=z_Dw7Qo1S40"
                          }
                          title={"Create Workflows"}
                          subTitle={"How to create a Workflow?"}
                        />
                      )}
                      <UserProfileDropdown />
                      <Credits />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </KBarWrapper>
  );
}
